import React, { useState } from "react";
import { bool, func, number, oneOfType, shape, string } from "prop-types";
import { Grid } from "@elliemae/ds-grid";
import { DSModal, MODAL_TYPE_V2, MODAL_SUB_TYPE_V2 } from "@elliemae/ds-modal";
import {
  DSInputMask,
  MASK_TYPES,
  DSCheckbox,
  CHECKBOX_VARIANT,
  DSLargeInputText,
  DSTextBox,
} from "@elliemae/ds-form";
import { DSButtonV2 } from "@elliemae/ds-button";
import { Delete } from "@elliemae/ds-icons";
import { CheckBoxWrapper } from "components/WrapContainer/styled";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { DSSeparator } from "@elliemae/ds-separator";
import {
  DSControlledDateTimePicker,
  CONTROLLED_DATE_TIME_PICKER_TYPES,
  getDayFromDateString,
} from "@elliemae/ds-controlled-form";
import { formatDate } from "services/helpers";
import { parseDecimals } from "./helpers";
import { StyledTextTitle, StyledCardTitle, StyledCard } from "./styled";

const ConcessionCard = ({ rowCard, onChange, onDeleteRow, index, title }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayTime = today.getTime();
  const approvalDateValidationMessage = "Cannot select past date";
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(false);
  const getApprovalDate = () => {
    if (rowCard?.approvalDate?.includes("T")) {
      return formatDate(rowCard.approvalDate, "Calendar");
    }
    return rowCard?.approvalDate ?? "";
  };
  const getIsBeforeToday = (dayString) => {
    const day = getDayFromDateString(dayString);
    if (day) {
      return day.getTime() > todayTime;
    }
    return false;
  };
  return (
    <>
      <StyledCard>
        <StyledCardTitle>
          <StyledTextTitle>{`${title} ${index + 1}`}</StyledTextTitle>
          <DSButtonV2
            buttonType="icon"
            onClick={() => setOpenModal(true)}
            size="s"
            aria-label="Delete row"
          >
            <Delete size="s" color={["brand-primary", 600]} />
          </DSButtonV2>
        </StyledCardTitle>
        <Grid
          cols={["20%", "40%", "40%"]}
          py={10}
          gutter={5}
          alignItems="flex-start"
        >
          <DSFormLayoutBlockItem
            label="Price"
            inputID="priceConcession"
            required
          >
            <DSInputMask
              mask={(num) =>
                MASK_TYPES.NUMBER({
                  prefix: "",
                  integerLimit: 1,
                  allowDecimal: true,
                  decimalLimit: 3,
                  allowNegative: true,
                })(num)
              }
              name="price"
              value={rowCard.price}
              labelText="Price"
              required
              onChange={({ target: { value } }) => {
                onChange(value, "price", index);
              }}
              onBlur={({ target: { value } }) => {
                onChange(parseDecimals(value, 3), "price", index);
              }}
              readOnly={rowCard.status === "Submitted"}
            />
          </DSFormLayoutBlockItem>
          <DSFormLayoutBlockItem
            label="Approval Date"
            inputID="approvalDateConcession"
            hasError={error}
            validationMessage={approvalDateValidationMessage}
            required
            fitContent
          >
            <DSControlledDateTimePicker
              date={getApprovalDate()}
              getIsDisabledDay={getIsBeforeToday}
              onDateChange={(newVal) => {
                onChange(newVal, "approvalDate", index);
                setError(getIsBeforeToday(newVal));
              }}
              type={CONTROLLED_DATE_TIME_PICKER_TYPES.DATE.FULL}
              disabled={rowCard.status === "Submitted"}
              hasError={error}
              pickerFooterMsg={error ? approvalDateValidationMessage : ""}
            />
          </DSFormLayoutBlockItem>
          <DSFormLayoutBlockItem
            label="Approved By"
            inputID="approvedByConcession"
            // feedbackMessage="Feedback message"
            // validationMessage="Validation Message too long is being shorted"
          >
            <DSTextBox
              value={rowCard.approvedBy}
              onChange={({ target: { value } }) =>
                onChange(value, "approvedBy", index)
              }
              maxLength={256}
              readOnly={rowCard.status === "Submitted"}
            />
          </DSFormLayoutBlockItem>
        </Grid>
        <Grid cols={["100%", "0"]}>
          <DSFormLayoutBlockItem
            label="Approval Reason"
            inputID="approvedReason"
            // feedbackMessage="Feedback message"
            // validationMessage="Validation Message too long is being shorted"
          >
            <DSLargeInputText
              value={rowCard.approvedReason}
              onChange={({ target: { value } }) =>
                onChange(value, "approvedReason", index)
              }
              maxLength={2000}
              disabled={rowCard.status === "Submitted"}
            />
          </DSFormLayoutBlockItem>
          <CheckBoxWrapper mt="14px">
            <DSCheckbox
              labelText="Persist during price updates"
              checked
              variant={CHECKBOX_VARIANT.DEFAULT}
              name="persistPrice"
              id="persistPrice"
              containerProps={{
                style: { visibility: "hidden" },
              }}
            />
          </CheckBoxWrapper>
        </Grid>
        <DSSeparator dashed style={{ marginBottom: `0`, marginTop: `24px` }} />
      </StyledCard>
      <DSModal
        centered
        confirmLabel="Ok"
        isOpen={openModal}
        modalSubType={MODAL_SUB_TYPE_V2.WARNING}
        modalType={MODAL_TYPE_V2.DECISION}
        onReject={() => setOpenModal(false)}
        onClose={() => setOpenModal(false)}
        onConfirm={() => {
          onDeleteRow(index);
          setOpenModal(false);
        }}
        rejectLabel="Cancel"
        showClose
        showRejectButton
        version={2}
      >
        <p>Do you want to delete this entry?</p>
      </DSModal>
    </>
  );
};

ConcessionCard.defaultProps = {
  index: 0,
  rowCard: {
    title: "",
    approvalDate: "",
    price: "",
    approvedBy: "",
    approvedReason: "",
    persist: true,
    persistDisabled: false,
  },
  title: "",
};

ConcessionCard.propTypes = {
  onChange: func.isRequired,
  onDeleteRow: func.isRequired,
  index: number,
  rowCard: shape({
    name: string,
    price: oneOfType([number, string]),
    persist: bool,
    persistDisabled: bool,
  }),
  title: string,
};
export default ConcessionCard;
